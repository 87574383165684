<script>
import { mapGetters } from 'vuex';
import store from '@/store';

export default {
  name: 'OEMPage',
  computed: {
    ...mapGetters({
      totalUsers: 'admin/totalUsers',
      totalLoggers: 'admin/totalLoggers',
      activeLoggers: 'admin/activeLoggers',
      pendingUserCount: 'admin/pendingUserCount',
      pendingUsers: 'admin/pendingUsers',
    }),
  },
  methods: {
    approveUser(uid) {
      this.$store.dispatch('admin/approveUser', uid)
        .then(() => this.$store.dispatch('admin/update_info'));
    },
  },
  beforeRouteEnter(from, to, next) {
    next((vm) => {
      vm.$Progress.start();
      store.dispatch('admin/update_info')
        .then(() => {
          vm.$Progress.finish();
          next();
        });
    });
  },
};
</script>

<template>
  <b-container>

    <b-row>
      <b-col cols='auto'>
        <h1>PMGateway Dashboard</h1>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-alert variant='info' v-bind:show='this.pendingUserCount > 0'>
          <b-link class='stretched-link text-secondary' v-b-toggle.pending-user-collapse>
            <fa-icon icon='exclamation-circle' class='mr-1'></fa-icon>
            There are {{ this.pendingUserCount }} pending users
          </b-link>
        </b-alert>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-collapse id='pending-user-collapse' v-if='this.pendingUserCount > 0'>
          <b-table-simple responsive>
            <b-thead>
              <b-tr>
                <b-td>Name</b-td>
                <b-td>Email</b-td>
                <b-td>Company</b-td>
                <b-td>Phone</b-td>
                <b-td></b-td>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for='user in pendingUsers' :key='user.id'>
                <b-td>
                  {{ user.name }}
                </b-td>
                <b-td>
                  {{ user.email }}
                </b-td>
                <b-td>
                  {{ user.company }}
                </b-td>
                <b-td>
                  {{ user.phone }}
                </b-td>
                <b-td>
                  <b-btn-group>
                    <b-btn variant='success' @click='approveUser(user.id)'>Approve</b-btn>
                    <b-btn variant='danger' disabled>Delete</b-btn>
                  </b-btn-group>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-collapse>
      </b-col>
    </b-row>

    <b-row class='mt-3'>
      <b-col cols=12 sm=6 md=4>
        <div class='dash-card'>
          <b-card title='Total Users' bg-variant='light'>
            <h1 class='text-center'>{{ this.totalUsers }}</h1>
          </b-card>
        </div>
      </b-col>
      <b-col cols=12 sm=6 md=4>
        <div class='dash-card'>
          <b-card title='Total Loggers' bg-variant='light'>
            <h1 class='text-center'>{{ this.totalLoggers }}</h1>
          </b-card>
        </div>
      </b-col>
      <b-col cols=12 sm=6 md=4>
        <div class='dash-card'>
          <b-card title='Active Loggers' bg-variant='light'>
            <h1 class='text-center'>{{ this.activeLoggers }}</h1>
          </b-card>
        </div>
      </b-col>

      <b-col cols=12 sm=6 md=4>
        <div class='dash-card'>
          <b-card title='User Settings' bg-variant='light'>
            <div class='text-center'>
              <b-link to='/oem/user' class='stretched-link'>
                <fa-icon icon='user-cog' size='5x'></fa-icon>
              </b-link>
            </div>
          </b-card>
        </div>
      </b-col>
      <b-col cols=12 sm=6 md=4>
        <div class='dash-card'>
          <b-card title='Logger Settings' bg-variant='light'>
            <div class='text-center'>
              <b-link to='/oem/logger' class='stretched-link'>
                <fa-icon icon='toolbox' size='5x'></fa-icon>
              </b-link>
            </div>
          </b-card>
        </div>
      </b-col>
      <b-col cols=12 sm=6 md=4>
        <div class='dash-card'>
          <b-card title='System Settings' bg-variant='light'>
            <div class='text-center'>
              <b-link to='/oem/system' class=stretched-link>
                <fa-icon icon='cog' size='5x'></fa-icon>
              </b-link>
            </div>
          </b-card>
        </div>
      </b-col>
    </b-row>

  </b-container>
</template>

<style scoped>
  .dash-card {
    width: 14em;
    height: 10em;
    margin-top: 5px;
  }
  .card {
    height: 100%;
  }
</style>
